import { useState } from 'react';
import { Link } from 'react-scroll';
import ImageGallery from 'react-image-gallery';
import { Modal, ModalBody, ModalFooter } from 'react-modern-modal';

export default function Work() {
  const [toggler, setToggler] = useState(false);
  const [imgSource, setimgSource] = useState([]);

  const ShowGallery = (item) => {
    if (!toggler) {
      const imagesData = projectDetails[item].images.map((img, index) => ({
        original: img,
        thumbnail: img,
        id: index,
      }));
      setimgSource(imagesData);
      setToggler(true);
    } else {
      setToggler(false);
      setimgSource([]);
    }
  };

  const projectDetails = [
    {
      title: 'Cafe',
      images: [
        'https://cdn.hajja.me/img/collect/work/addu_cafe/image201.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_cafe/image101.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_cafe/image301.jpg',
      ],
    },
    {
      title: 'Affagato',
      images: [
        'https://cdn.hajja.me/img/collect/work/affagato/RENDER01.jpg',
        'https://cdn.hajja.me/img/collect/work/affagato/RENDER2.jpg',
        'https://cdn.hajja.me/img/collect/work/affagato/RENDER3.jpg',
        'https://cdn.hajja.me/img/collect/work/affagato/RENDER4.jpg',
      ],
    },
    {
      title: 'Maaveyo Mosque',
      images: [
        'https://cdn.hajja.me/img/collect/work/maaveyo/1.jpg',
        'https://cdn.hajja.me/img/collect/work/maaveyo/2.jpg',
        'https://cdn.hajja.me/img/collect/work/maaveyo/3.jpg',
        'https://cdn.hajja.me/img/collect/work/maaveyo/4.jpg',
        'https://cdn.hajja.me/img/collect/work/maaveyo/5.jpg',
        'https://cdn.hajja.me/img/collect/work/maaveyo/6.jpg',
      ],
    },
    {
      title: 'Custom Minimal Bedroom',
      images: [
        'https://cdn.hajja.me/img/collect/work/bedroom/test401.jpg',
        'https://cdn.hajja.me/img/collect/work/bedroom/test301.jpg',
      ],
    },
    {
      title: 'Complete House in Addu',
      images: [
        'https://cdn.hajja.me/img/collect/work/addu_house/s201.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_house/2.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_house/3.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_house/101.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_house/s101.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_house/s201.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_house/x123.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_house/x124.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_house/x125.jpg',
        'https://cdn.hajja.me/img/collect/work/addu_house/TOILEWT01.jpg',
      ],
    },
    {
      title: 'Executive Cabin',
      images: [
        'https://cdn.hajja.me/img/collect/work/exec_cabin/2.jpg',
        'https://cdn.hajja.me/img/collect/work/exec_cabin/101.jpg',
        'https://cdn.hajja.me/img/collect/work/exec_cabin/301.jpg',
        'https://cdn.hajja.me/img/collect/work/exec_cabin/401.jpg',
        'https://cdn.hajja.me/img/collect/work/exec_cabin/501.jpg',
      ],
    },
    {
      title: 'Office Space',
      images: [
        'https://cdn.hajja.me/img/collect/work/office_space/render2.jpg',
        'https://cdn.hajja.me/img/collect/work/office_space/render3.jpg',
        'https://cdn.hajja.me/img/collect/work/office_space/render10.jpg',
        'https://cdn.hajja.me/img/collect/work/office_space/render12.jpg',
        'https://cdn.hajja.me/img/collect/work/office_space/render13.jpg',
        'https://cdn.hajja.me/img/collect/work/office_space/render14.jpg',
        'https://cdn.hajja.me/img/collect/work/office_space/render15.jpg',
        'https://cdn.hajja.me/img/collect/work/office_space/render16.jpg',
      ],
    },
    {
      title: 'Ithaafushi Restaurant',
      images: [
        'https://cdn.hajja.me/img/collect/work/ithaafushi/1.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/2.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/3.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/4.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/5.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/6.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/7.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/8.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/9.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/12.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/13.jpg',
        'https://cdn.hajja.me/img/collect/work/ithaafushi/1101.jpg',
      ],
    },
    {
      title: 'Living Room',
      images: [
        'https://cdn.hajja.me/img/collect/work/living/new1.jpg',
        'https://cdn.hajja.me/img/collect/work/living/new2.jpg',
      ],
    },
    {
      title: 'Dhifushi Guest House',
      images: [
        'https://cdn.hajja.me/img/collect/work/guesthouse/dif101.jpg',
        'https://cdn.hajja.me/img/collect/work/guesthouse/201.jpg',
        'https://cdn.hajja.me/img/collect/work/guesthouse/401.jpg',
        'https://cdn.hajja.me/img/collect/work/guesthouse/301.jpg',
      ],
    },
    {
      title: 'Eriyadhu Resort Restaruant',
      images: [
        'https://cdn.hajja.me/img/collect/work/resort/optioiontwo_01.jpg',
      ],
    },
    {
      title: 'Outdoor Cafe',
      images: [
        'https://cdn.hajja.me/img/collect/work/outdoor_cafe/1.jpg',
        'https://cdn.hajja.me/img/collect/work/outdoor_cafe/2.jpg',
        'https://cdn.hajja.me/img/collect/work/outdoor_cafe/3.jpg',
      ],
    },
  ];

  return (
    <>
      <section
        data-scroll-data="2"
        id="projects"
        className="section bg-orange-50"
      >
        <div className="container">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>Projects</span>
              </h3>
            </div>
          </div>
          <div className="lightbox-gallery portfolio-box">
            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/addu_cafe/image101.jpg"
                    className="image_cover"
                    title=""
                    alt=""
                    preload="true"
                    onClick={() => ShowGallery(0)}
                  />
                  <Link
                    onClick={() => ShowGallery(0)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Restaurants & Cafes</span>
                  </h6>
                  <h4>Cafe </h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(0)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    className="image_cover"
                    src="https://cdn.hajja.me/img/collect/work/affagato/RENDER4.jpg"
                    title=""
                    alt=""
                    preload="true"
                    onClick={() => ShowGallery(1)}
                  />
                  <Link
                    onClick={() => ShowGallery(1)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Restaurants & Cafes</span>
                  </h6>
                  <h4>Affogato</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(1)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/maaveyo/1.jpg"
                    preload="true"
                    className="image_cover"
                    title=""
                    alt=""
                    onClick={() => ShowGallery(2)}
                  />
                  <Link
                    onClick={() => ShowGallery(2)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Public</span>
                  </h6>
                  <h4>Maaveyo Mosque</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(2)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/bedroom/test401.jpg"
                    title=""
                    className="image_cover"
                    alt=""
                    preload="true"
                    onClick={() => ShowGallery(3)}
                  />
                  <Link
                    onClick={() => ShowGallery(3)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Home Design</span>
                  </h6>
                  <h4>Custom Minimal Bedroom</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(3)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/addu_house/s201.jpg"
                    title=""
                    className="image_cover"
                    alt=""
                    preload="true"
                    onClick={() => ShowGallery(4)}
                  />
                  <Link
                    onClick={() => ShowGallery(4)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Home Design</span>
                  </h6>
                  <h4>Complete House in Addu (Clay Render) </h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(4)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/outdoor_cafe/1.jpg"
                    title=""
                    className="image_cover"
                    alt=""
                    preload="true"
                    onClick={() => ShowGallery(11)}
                  />
                  <Link
                    onClick={() => ShowGallery(11)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Restaurants & Cafes</span>
                  </h6>
                  <h4>Outdoor Cafe</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(11)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 ">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/exec_cabin/2.jpg"
                    title=""
                    alt=""
                    className="image_cover"
                    preload="true"
                    onClick={() => ShowGallery(5)}
                  />
                  <Link
                    onClick={() => ShowGallery(5)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Office Space</span>
                  </h6>
                  <h4>Executive Cabin</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(5)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/office_space/render12.jpg"
                    title=""
                    alt=""
                    className="image_cover"
                    preload="true"
                    onClick={() => ShowGallery(6)}
                  />
                  <Link
                    onClick={() => ShowGallery(6)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Office Space</span>
                  </h6>
                  <h4>Reception Area</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(6)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/ithaafushi/3.jpg"
                    title=""
                    alt=""
                    className="image_cover"
                    preload="true"
                    onClick={() => ShowGallery(7)}
                  />
                  <Link
                    onClick={() => ShowGallery(7)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Resort & Hotels</span>
                  </h6>
                  <h4>Ithaafushi BOH</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(7)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/resort/optioiontwo_01.jpg"
                    title=""
                    alt=""
                    className="image_cover"
                    preload="true"
                    onClick={() => ShowGallery(10)}
                  />
                  <Link
                    onClick={() => ShowGallery(10)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Resort & Hotels</span>
                  </h6>
                  <h4>Eriyadhu Resort Restaruant</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(10)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/living/new1.jpg"
                    title=""
                    alt=""
                    className="image_cover"
                    preload="true"
                    onClick={() => ShowGallery(8)}
                  />
                  <Link
                    onClick={() => ShowGallery(8)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Home Design</span>
                  </h6>
                  <h4>Living Room</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(8)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                <div className="portfolio-img cursor-pointer">
                  <img
                    src="https://cdn.hajja.me/img/collect/work/guesthouse/dif101.jpg"
                    title=""
                    alt=""
                    className="image_cover"
                    preload="true"
                    onClick={() => ShowGallery(9)}
                  />
                  <Link
                    onClick={() => ShowGallery(9)}
                    className="gallery-link gallery-link-icon cursor-pointer"
                  >
                    <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                <div className="portfolio-text">
                  <h6>
                    <span>Resort & Hotels</span>
                  </h6>
                  <h4>Dhifushi Guest House</h4>
                  <div className="btn-bar">
                    <div
                      className="px-btn px-btn-theme px_modal"
                      onClick={() => ShowGallery(9)}
                    >
                      View Project{' '}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal isOpen={toggler} onClose={ShowGallery} size="lg">
        <ModalBody>
          <ImageGallery items={imgSource} />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
}
