import Hajja from './components/Origin/hajja';
import './assets/css/style.css';
import './assets/vendor/font-awesome/css/all.min.css';
import './assets/js/main.js';
import 'react-image-gallery/styles/css/image-gallery.css';
function App() {
  return <Hajja />;
}

export default App;
