import { useState } from 'react';
import { Link } from 'react-scroll';

/*--------------------
* Header Menu
----------------------*/
function Menu(props) {
  const { id, name, link, toggleMenu } = props;

  const handleClick = () => {
    toggleMenu();
  };

  return (
    <li>
      <Link data-scroll-nav={id} smooth={true} to={link} onClick={handleClick}>
        <span>{name}</span>
      </Link>
    </li>
  );
}

export default function Header() {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const header_menus = [
    { id: 0, name: 'Home', scroll_link: 'home' },
    { id: 1, name: 'Portfolio', scroll_link: 'portfolio' },
    { id: 2, name: 'Projects', scroll_link: 'projects' },
    { id: 3, name: 'Contact', scroll_link: 'contactus' },
  ];

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <>
      <header className="main-header fixed left-0 right-0 z-[111] py-5">
        <div className="container flex items-center justify-between one-page-nav relative">
          <button
            className="lg:hidden mobile_toggle w-[40px] flex flex-col"
            onClick={toggleMenu}
          >
            <span className="w-[25px] h-[2px] bg-slate-900 inline-block"></span>
            <span className="w-[25px] h-[2px] bg-slate-900 inline-block my-[5px]"></span>
            <span className="w-[25px] h-[2px] bg-slate-900 inline-block"></span>
          </button>
          <div
            className={`navbar-collapse flex ${isOpenMenu ? 'menu-open' : ''}`}
          >
            <ul className="navbar lg:mx-auto flex flex-col lg:flex-row p-4 lg:p-0">
              {header_menus.map((val, i) => (
                <Menu
                  key={i}
                  id={val.id}
                  name={val.name}
                  link={val.scroll_link}
                  toggleMenu={toggleMenu}
                />
              ))}
            </ul>
          </div>
          <div className="ms-auto hidden lg:flex">
            <Link
              className="px-btn px-btn-theme"
              data-scroll-nav="2"
              to="contactus"
            >
              Contact Now
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}
