import Header from '../Header/header';
import Banner from '../Banner/banner';
import About from '../About/about';
import Work from '../Work/work';
import Contact from '../Contact/contact';
import Footer from '../Footer/footer';
import Intrested from '../Intrested/intrested';
import Skills from '../Skills/skills.js';

export default function Hajja() {
  return (
    <>
      <Header />
      <main className="wrapper">
        <Banner />

        <About />

        <Intrested />
        <Skills />
        <Work />
        <Contact />
      </main>
      <Footer />
    </>
  );
}
