/*--------------------
* Skill List Section
----------------------*/
export default function SkillList() {
  return (
    <>
      <section
        data-scroll-data="1"
        id="portfolio"
        className="section bg-white-10"
      >
        <div className="container mx-auto px-4 py-8">
          <div className="grid section-heading">
            <div className="lg:col-span-6 text-center mx-auto">
              <h3>
                <span>Portfolio</span>
              </h3>
            </div>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Education</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-orange-50 rounded-lg p-6 shadow-md">
                <h3 className="text-xl font-semibold mb-2">
                  Foundation in Built & Enviroment
                </h3>
                <p className="text-sm text-gray-600 mb-4">
                  <b>Limkokwing University </b> - Cyberjaya , Malaysia
                </p>
                <p className="text-sm">2012 - 2013</p>
              </div>

              <div className="bg-orange-50 rounded-lg p-6 shadow-md">
                <h3 className="text-xl font-semibold mb-2">
                  BA Hons in Interior Architecture
                </h3>
                <p className="text-sm text-gray-600 mb-4">
                  <b>Taylors University</b> - Subang Jaya , Malaysia
                </p>

                <p className="text-sm">2014 - 2017</p>
              </div>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-bold mb-4">Work Experience</h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
              <div className="bg-orange-300 rounded-lg p-6 shadow-md bg-orange-10">
                <h3 className="text-xl font-semibold mb-2">
                  Deparment of Immigration Maldives
                </h3>
                <p className="text-sm text-gray-600 mb-4">Grade 3 Officer</p>
                <p className="text-sm">2009 - 2012</p>
              </div>

              <div className="bg-orange-300 rounded-lg p-6 shadow-md bg-orange-10">
                <h3 className="text-xl font-semibold mb-2">
                  3B Ideation - Kelana Jaya ,Malaysia
                </h3>

                <p className="text-sm text-gray-600 mb-4">
                  Intern - Junior Interior Designer
                </p>
                <p className="text-sm">2015 (2 Months) </p>
              </div>

              <div className="bg-orange-300 rounded-lg p-6 shadow-md bg-orange-10">
                <h3 className="text-xl font-semibold mb-2">
                  Group X Assosiates - Male' ,Maldives
                </h3>
                <p className="text-sm text-gray-600 mb-4">
                  Intern - Junior Interior Architect
                </p>
                <p className="text-sm">2015-2016 (6 Months) </p>
              </div>

              <div className="bg-orange-300 rounded-lg p-6 shadow-md bg-orange-10">
                <h3 className="text-xl font-semibold mb-2">
                  Hotels & Resorts Construction Pvt Ltd - Male' ,Maldives
                </h3>
                <p className="text-sm text-gray-600 mb-4">Interior Designer</p>
                <p className="text-sm">2018-2020</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
