/*--------------------
* Contact Section
----------------------*/
import { useState } from 'react';

export default function Contact() {
  const [Phone, setPhone] = useState('+960 7****');
  const [Email, setEmail] = useState('***@hajja.**');
  const [Hidden, setHidden] = useState(true);

  const ShowContact = () => {
    setPhone('+960 7696324');
    setEmail('info@hajja.me');
    setHidden(false);

    setTimeout(HideContact, 60000);
  };

  function HideContact() {
    setPhone('+960 7****');
    setEmail('***@hajja.**');
    setHidden(true);
  }
  return (
    <>
      <section
        data-scroll-data="3"
        id="contactus"
        className="section contactus-section bg-slate-900"
        style={{
          backgroundImage:
            'url(https://cdn.hajja.me/img/effect/bg-effect-3.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6 flex">
              <div className="lg:max-w-[410px] w-full lg:ml-auto pt-[50px] lg:pt-0">
                <div className="pb-10">
                  <img
                    className="w-full"
                    src="img/contact-bg.png"
                    title=""
                    alt=""
                    height={581}
                    width={424}
                  />
                </div>

                <ul className="contact-infos">
                  <li>
                    <div className="icon bg-1">
                      <i className="fas fa-phone-alt "></i>
                    </div>
                    <div className="col">
                      <h5>Phone</h5>
                      <p>{Phone}</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon bg-2">
                      <i className="fas fa-envelope "></i>
                    </div>
                    <div className="col">
                      <h5>Mail</h5>
                      <p>{Email}</p>
                    </div>
                  </li>
                </ul>
                {Hidden ? (
                  <>
                    <div className="my-8">
                      {' '}
                      <button
                        onClick={ShowContact}
                        className="px-btn-theme cursor-pointer p-1"
                      >
                        {' '}
                        Click to reveal details
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
