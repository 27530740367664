import { Link } from 'react-scroll';

/*--------------------
* About Section
----------------------*/
export default function About() {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
            <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0">
              <img
                className="mx-auto"
                src="https://cdn.hajja.me/img/aboutme-banner.png"
                title="Banner"
                alt="Banner"
                height={680}
                width={645}
              />
            </div>
            <div className="lg:col-span-6 lg:pl-12">
              <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">
                I am an Interior Designer, driven by a profound passion for
                creating and transforming spaces.
              </h3>

              <div className="pt-6">
                <Link className="px-btn px-btn-theme mr-4" to="contactus">
                  Contact Me
                </Link>
                <Link className="px-btn px-btn-dark" to="portfolio">
                  Portfolio
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
